import { Box, Breadcrumbs, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

// helper
import { checkPermission } from "../../helper/PermissionHelper";
import { pageName } from "../../reducers/commonSlice";
import { user } from "../../reducers/userSlice";

// components
import ActionFooter from "../../components/ActionFooter";
import FullScreenLoading from "../../components/FullScreenLoading";
import BaseInfo from "../../components/category/BaseInfo";
import Skeleton from "../../components/category/Skeleton";

// api
import { CategoryApi } from "../../api/CategoryApi";
import { StoreApi } from "../../api/StoreApi";

export default function Edit() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({
    id: null,
    name: "",
    images: [],
    appliedStores: [],
    description: "",
    priority: 0,
    showOnPos: true,
    showOnOrdering: true,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  const [haveDeletePermission, setHaveDeletePermission] = useState(false);
  const currentUser = useSelector(user);
  const currentPage = useSelector(pageName);
  const [storesList, setStoresList] = useState([]);

  useEffect(() => {
    if (!id) {
      navigate(-1);
    }
    fetchStoresList();
    fetchData();
  }, []);

  useEffect(() => {
    const deletePermission = checkPermission(currentUser, currentPage, "delete");

    setHaveDeletePermission(deletePermission);
  }, [currentUser, currentPage]);

  const fetchStoresList = async () => {
    try {
      setIsLoading(true);
      const response = await StoreApi.list(1, -1, []);
      console.log("response", response.data);
      if (response.data) {
        setStoresList(response.data);
      }
    } catch (error) {
      console.log(error);
    }
    finally {
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await CategoryApi.details(id);
      setData(response);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const update = async () => {
    try {
      setFullScreenLoading(true);

      const success = await CategoryApi.update(id, data);
      if (success) {
        toast.success(t("layout.updated_successfully"));
      }

    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const deleteData = async () => {
    try {
      setFullScreenLoading(true);
      const success = await CategoryApi.deleteData(id);
      if (success) {
        toast.success(t("layout.deleted_successfully"));
        navigate("/category");
      }

    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/category"}>{t("menu.category")}</Link>
        <Typography color="text.primary">{t("layout.edit")}</Typography>
      </Breadcrumbs>
      <Box component="form">
        <BaseInfo
          data={data}
          setData={setData}
          deleteData={deleteData}
          haveDeletePermission={haveDeletePermission}
          storesList={storesList}
        />
        <Box sx={{ my: 8 }} />
        <ActionFooter onSubmit={update} onCancel={onCancel}/>
      </Box>
    </Container>
  );
}
