import { Box, Breadcrumbs, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

// helper
import { getServiceProductType } from "../../helper/GlobalValueHelper";
import { checkPermission } from "../../helper/PermissionHelper";
import { pageName } from "../../reducers/commonSlice";
import { user } from "../../reducers/userSlice";

// components
import ActionFooter from "../../components/ActionFooter";
import FullScreenLoading from "../../components/FullScreenLoading";
import Images from "../../components/product/Images";
import BaseInfo from "../../components/product/BaseInfo";
import Service from "../../components/product/Service";
import Simple from "../../components/product/Simple";
import Skeleton from "../../components/product/Skeleton";
import Variants from "../../components/product/Variants";

// api
import { ProductApi } from "../../api/ProductApi";

export default function Edit() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({
    title: "",
    type: "normal",
    basePrice: 0,
    inventories: [],
    categories: [],
    supplier: null,
    description: "",
    options: [],
    variants: [],
    images: [],
    isSimple: true,
    trackInventory: true,
    printKitchenReceipt: false,
    isAvailable: true,
    services: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  const [haveDeletePermission, setHaveDeletePermission] = useState(false);
  const currentUser = useSelector(user);
  const currentPage = useSelector(pageName);

  const [searchParams] = useSearchParams();
  const isApp = searchParams.get("isApp");

  useEffect(() => {
    if (!id) {
      navigate(-1);
    }
    fetchData();
  }, []);

  useEffect(() => {
    const deletePermission = checkPermission(currentUser, currentPage, "delete");

    setHaveDeletePermission(deletePermission);
  }, [currentUser, currentPage]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const result = await ProductApi.details(id);
      // console.log("product result: ", result);
      setData(result);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async () => {
    try {
      console.log("submit", data);

      let newProduct = { ...data };
      if (!newProduct.title) {
        toast.error("please input product name");
        return;
      }

      if (newProduct.categories.length <= 0) {
        toast.error("please select category");
        return;
      }

      if (newProduct.options.length <= 0) {
        toast.error("please select options");
        return;
      }

      if (newProduct.cost < 0 || newProduct.cost == "" || !newProduct.cost) {
        newProduct.cost = 0;
      }

      if (newProduct.price < 0 || newProduct.price == "" || !newProduct.price) {
        newProduct.price = 0;
      }

      setFullScreenLoading(true);
      const success = await ProductApi.updateProduct(newProduct);
      if (success) {
        toast.success(t("layout.updated_successfully"));
        fetchData();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  const deleteData = async () => {
    setFullScreenLoading(true);
    const success = await ProductApi.deleteData(data.id);
    setFullScreenLoading(false);
    if (success) {
      toast.success("Product deleted successfully");
      navigate(`/products`);
    } else {
      toast.error("Failed to delete product");
    }
  };

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={isApp ? `/products?isApp=${isApp}` : `/products`}>{t("menu.products")}</Link>
        <Typography color="text.primary">{t("layout.edit")}</Typography>
      </Breadcrumbs>
      <Box component="form">
        <BaseInfo
          data={data}
          setData={setData}
          isEditing
          deleteData={deleteData}
          haveDeletePermission={haveDeletePermission}
        />
        <Box sx={{ my: 5 }} />
        {data.isSimple ? (
          <Simple data={data} setData={setData} isEditing />
        ) : (
          <Variants data={data} setData={setData} isEditing />
        )}
        {data.type == getServiceProductType() && (
          <Box>
            <Box sx={{ my: 5 }} />
            <Service data={data} setData={setData} />
          </Box>
        )}
        <Box sx={{ my: 5 }} />
        <Images data={data} setData={setData} />
        <Box sx={{ my: 5 }} />
        <ActionFooter onSubmit={onSubmit} onCancel={onCancel} />
      </Box>
    </Container>
  );
}
