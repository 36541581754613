import { Breadcrumbs, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

// components
import ActionFooter from "../../../components/ActionFooter";
import FullScreenLoading from "../../../components/FullScreenLoading";
import OptionBaseInfo from "../../../components/product/OptionBaseInfo";

// api
import { ProductApi } from "../../../api/ProductApi";

export default function Edit() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({
    id: null,
    name: "",
    values: [],
    taskLocation: "",
  });
  const [isDisabled, setIsDisabled] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [fullScreenLoading, setFullScreenLoading] = useState(false);

  useEffect(() => {
    if (!id) {
      navigate(-1);
    }
    if (id == 1) {
      setIsDisabled(true);
    }
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const result = await ProductApi.productOptionDetails(id);
      setData(result);
      console.log(result);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async () => {
    try {
      if (!data.name || !data.values.length) {
        toast.error(t("error.please_enter_all_required_fields"));
        return;
      }
      setFullScreenLoading(true);

      console.log(data);

      const success = await ProductApi.updateProductOption(id, data);
      if (success) {
        toast.success(t("layout.created_successfully"));
      }

    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  const deleteData = async () => {
    try {
      setFullScreenLoading(true);
      const success = await ProductApi.deleteProductOption(id);
      if (success) {
        toast.success(t("layout.deleted_successfully"));
        navigate("/products/options");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/settings"}>{t("menu.settings")}</Link>
        <Link to={"/products/options"}>{t("product.product_options")}</Link>
        <Typography color="text.primary">{t("layout.edit")}</Typography>
      </Breadcrumbs>
      <OptionBaseInfo data={data} setData={setData} isDisabled={isDisabled} deleteData={deleteData}/>
      <ActionFooter onSubmit={onSubmit} onCancel={onCancel} />
    </Container>
  );
}
