import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  IconButton,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { useSelector } from "react-redux";
import { toast } from "react-toastify";

// helper
import { checkPermission } from "../../../../helper/PermissionHelper";
import { pageName } from "../../../../reducers/commonSlice";
import { user } from "../../../../reducers/userSlice";

// icons
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";

// components
import TableSkeleton from "../../../../components/TableSkeleton";

// api
import { ProductApi } from "../../../../api/ProductApi";

export default function List() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(parseInt(searchParams.get("page")) || 1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState();
  const [searchStr, setSearchStr] = useState();
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [haveCreatePermission, setHaveCreatePermission] = useState(false);
  const [haveUpdatePermission, setHaveUpdatePermission] = useState(false);
  const currentUser = useSelector(user);
  const currentPage = useSelector(pageName);
  const [colSpan, setColSpan] = useState(4);

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    if (!filter || filter.length <= 0) return;
    setPage(1);
    fetchData();
  }, [filter]);

  useEffect(() => {
    const createPermission = checkPermission(currentUser, currentPage, "create");
    setHaveCreatePermission(createPermission);

    const updatePermission = checkPermission(currentUser, currentPage, "update");
    setHaveUpdatePermission(updatePermission);
  }, [currentUser, currentPage]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await ProductApi.productOptionValueList(page, pageSize, filter);

      const data = response.data.filter(item => item.id != 1);

      setData(data);
      setTotal(response.total);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const viewDetail = id => {
    navigate(`/products/options/values/${id}`);
  };

  const create = () => {
    navigate(`/products/options/values/create`);
  };

  const onChangePage = value => {
    setPage(value);
    setSearchParams({ page: value });
  };

  const onChangeSearchField = value => {
    setSearchStr(value);
  };

  const search = () => {
    setPage(1);
    setFilter([{ field: "name", value: searchStr }]);
  };

  return (
    <Container maxWidth="false">
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/settings"}>{t("menu.settings")}</Link>
        <Typography color="text.primary">{t("product.option_values")}</Typography>
      </Breadcrumbs>
      <Card variant="outlined">
        <CardHeader
          title={t("product.option_values")}
          action={
            haveCreatePermission && (
              <Button
                color="inherit"
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={create}
              >
                {t("product.new_value")}
              </Button>
            )
          }
        />

        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              pb: 1,
            }}
          >
            <TextField
              placeholder={t("layout.search_by_title")}
              onChange={event => {
                onChangeSearchField(event.target.value);
              }}
              onKeyDown={event => {
                if (event.key == "Enter") {
                  search();
                }
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    edge="end"
                    onClick={() => {
                      search();
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
          </Box>
          <TableContainer elevation={0} component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell width={"10%"}>{t("layout.id")}</TableCell>
                  <TableCell width={"10%"}>{t("layout.name")}</TableCell>
                  <TableCell width={"10%"}>{t("setting.updated_by")}</TableCell>
                  <TableCell width={"10%"}>{t("setting.updated_at")}</TableCell>
                </TableRow>
              </TableHead>
              {isLoading ? (
                <TableSkeleton row={pageSize} colSpan={colSpan} />
              ) : (
                <TableBody>
                  {!data.length && (
                    <TableRow>
                      <TableCell colSpan={colSpan} align="center">
                        {t("layout.no_data")}
                      </TableCell>
                    </TableRow>
                  )}
                  {data.map(row => (
                    <TableRow
                      hover
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: "pointer" }}
                      onClick={() =>
                        haveUpdatePermission
                          ? viewDetail(row.id)
                          : toast.error(t("error.no_update_permission"))
                      }
                    >
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.updatedBy}</TableCell>
                      <TableCell>{row.updatedAt}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </CardContent>

        <CardActions sx={{ justifyContent: "space-between", px: 2 }}>
          <Typography variant="body2">
            {t("layout.total")}: {total}
          </Typography>
          <Stack spacing={2}>
            <Pagination
              page={page}
              count={Math.ceil(total / pageSize)}
              shape="rounded"
              onChange={(event, value) => {
                onChangePage(value);
              }}
            />
          </Stack>
        </CardActions>
      </Card>
    </Container>
  );
}
