import axios from "../helper/AxiosHelper";

const list = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
    sort: "id.DESC",
  };
  const response = await axios.get(`/member/query?query=${JSON.stringify(query)}&fields=all`);
  const result = response.data.result;
  return {
    data: result.data.map(data => {
      return {
        id: data.id,
        firstName: data.first_name,
        lastName: data.last_name,
        email: data.email,
        phoneNumber: data.phone_number,
        photo: data.photo || "",
        remark: data.remark,
        memberLevel: data.member_level,
        points: data.points,
        updatedAt: data.updated_at,
        createdAt: data.created_at,
        updatedBy: data.updated_by_info ? data.updated_by_info.username : "",
        createdBy: data.created_by_info ? data.created_by_info.username : "",
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const details = async id => {
  const response = await axios.get(`member/show/${id}?fields=all`);

  const result = response.data.result;
  return {
    id: result.id,
    firstName: result.first_name,
    lastName: result.last_name,
    photo: result.photo || "",
    email: result.email,
    phoneNumber: result.phone_number,
    remark: result.remark,
    memberLevel: result.member_level,
    points: result.points,
    updatedAt: result.updated_at,
    createdAt: result.created_at,
    updatedBy: result.updated_by_info ? result.updated_by_info.username : "",
    createdBy: result.created_by_info ? result.created_by_info.username : "",
  };
};

const create = async input => {
  const formData = new FormData();
  const data = {
    first_name: input.firstName,
    last_name: input.lastName,
    email: input.email,
    phone_number: input.phoneNumber,
    password: input.password,
    photo: input.photo,
    remark: input.remark,
    member_level: input.memberLevel,
    points: input.points,
    upload_files: input.uploadFiles?.length || undefined,
  };

  formData.append("data", JSON.stringify(data));

  if (input.uploadFiles) {
    input.uploadFiles.forEach((file, index) => {
      formData.append(`file_${index + 1}`, file);
    });
  }

  const response = await axios.post("/member/create", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data.result.insert_id;
};

const update = async (id, input) => {
  const formData = new FormData();
  const data = {
    first_name: input.firstName,
    last_name: input.lastName,
    email: input.email,
    phone_number: input.phoneNumber,
    photo: input.photo,
    remark: input.remark,
    member_level: input.memberLevel,
    points: input.points,
    password: input.password != "" ? input.password : undefined,
    upload_files: input.uploadFiles || undefined,
  };

  formData.append("data", JSON.stringify(data));

  if (input.uploadFiles) {
    input.uploadFiles.forEach((file, index) => {
      formData.append(`file_${index + 1}`, file);
    });
  }

  const response = await axios.post(`member/update/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data.success;
};

const deleteData = async id => {
  const response = await axios.post(`member/delete/${id}`);
  return response.data.success;
};

const memberPointsHistorylist = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
    sort: "id.DESC",
  };
  const response = await axios.get(
    `/memberPointsHistory/query?query=${JSON.stringify(query)}&fields=all`,
  );
  const result = response.data.result;

  return {
    data: result.data.map(data => {
      return {
        id: data.id,
        event: data.event,
        qtyChange: data.qty_change,
        remark: data.remark,
        balance: data.balance,
        createdAt: data.created_at,
        createdBy: data.created_by_info ? data.created_by_info.username : "",
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const changeMemberPoints = async changes => {
  const data = {
    member_id: changes.memberID,
    event: changes.event,
    qty_change: changes.qtyChange,
    remark: changes.remark,
  };

  const response = await axios.post("/member/changeMemberPoints", data);

  return response.data.success;
};

export const MemberApi = {
  list,
  details,
  create,
  update,
  deleteData,
  memberPointsHistorylist,
  changeMemberPoints,
};
