import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

// components
import TableSkeleton from "../TableSkeleton";

// api
import { InventoryApi } from "../../api/InventoryApi";

export default function History({ InventoryId }) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [filter, setFilter] = useState([{ field: "inventory_id", value: InventoryId }]);
  const [total, setTotal] = useState(0);
  const [colSpan, setColSpan] = useState(7);

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    if (!filter || filter.length <= 0) return;
    setPage(1);
    fetchData();
  }, [filter]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await InventoryApi.inventoryStockHistory(page, pageSize, filter);
      setData(response.data);
      setTotal(response.total);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangePage = value => {
    setPage(value);
    setSearchParams({ page: value });
  };

  return (
    <Card variant="outlined">
      <CardHeader title={t("inventory.history")} />
      <CardContent>
        <TableContainer elevation={0}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t("layout.id")}</TableCell>
                <TableCell>{t("layout.name")}</TableCell>
                <TableCell>{t("inventory.action")}</TableCell>
                <TableCell>{t("inventory.change")}</TableCell>
                <TableCell>{t("inventory.remaining_stock")}</TableCell>
                <TableCell>{t("layout.remark")}</TableCell>
                <TableCell>{t("layout.date")}</TableCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableSkeleton row={pageSize} colSpan={colSpan} />
            ) : (
              <TableBody>
                {data.map(row => (
                  <TableRow hover key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell>
                      <Box display={"flex"} flexDirection={"column"}>
                        <Box>{row.variant ? row.variant.productName : "Deleted variant"}</Box>
                        <Box>SKU: {row.variant ? row.variant.sku : ""}</Box>
                        {/* <Box>{row.variant ? getVariantName(row) : ""}</Box> */}
                      </Box>
                    </TableCell>
                    <TableCell>{row.action ? t(`inventory.${row.action}`) : row.action}</TableCell>
                    <TableCell>{row.qtyChange}</TableCell>
                    <TableCell>{row.remainingStock}</TableCell>
                    <TableCell>{row.remark}</TableCell>
                    <TableCell>{row.createdAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </CardContent>

      <CardActions sx={{ justifyContent: "space-between", px: 2 }}>
        <Typography variant="body2">
          {t("layout.total")}: {total}
        </Typography>
        <Stack spacing={2}>
          <Pagination
            page={page}
            count={Math.ceil(total / pageSize)}
            shape="rounded"
            onChange={(event, value) => {
              onChangePage(value);
            }}
          />
        </Stack>
      </CardActions>
    </Card>
  );
}
