import { Breadcrumbs, Container, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Translation, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

// helper
import { checkPermission } from "../../../helper/PermissionHelper";
import { pageName } from "../../../reducers/commonSlice";
import { user } from "../../../reducers/userSlice";

// components
import ActionFooter from "../../../components/ActionFooter";
import FullScreenLoading from "../../../components/FullScreenLoading";
import BaseInfo from "../../../components/permission/BaseInfo";

// api
import { PermissionModuleApi } from "../../../api/PermissionModuleApi";

// validation schema
const validationSchema = Yup.object().shape({
  module: Yup.string().required(
    <Translation>{t => t("error.please_fill_in_this_field")}</Translation>,
  ),
});

export default function Edit() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const formik = useFormik({
    initialValues: {
      module: "",
      actions: [],
    },
    validateOnMount: true,
    validationSchema: validationSchema,
  });
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  let moduleId = id;
  const [haveDeletePermission, setHaveDeletePermission] = useState(false);
  const currentUser = useSelector(user);
  const currentPage = useSelector(pageName);

  useEffect(() => {
    if (!moduleId) {
      navigate(-1);
    }
    fetchData();
  }, []);

  useEffect(() => {
    const deletePermission = checkPermission(currentUser, currentPage, "delete");

    setHaveDeletePermission(deletePermission);
  }, [currentUser, currentPage]);

  const fetchData = async () => {
    try {
      setFullScreenLoading(true);
      const response = await PermissionModuleApi.details(moduleId);
      formik.setValues(response);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const update = async () => {
    try {
      formik.setTouched({
        idleTime: true,
      });

      formik.validateForm();

      if (!formik.isValid) {
        return;
      }

      if (formik.values.actions.length === 0 || !formik.values.actions) {
        toast.error(t("error.please_at_least_input_one_action"));
        return;
      }

      const module = {
        ...formik.values,
      };

      setFullScreenLoading(true);

      const success = await PermissionModuleApi.update(moduleId, module);

      if (success) {
        toast.success(t("layout.updated_successfully"));
        fetchData();
      }

    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  const deletePermissionModule = async () => {
    try {
      setFullScreenLoading(true);
      const success = await PermissionModuleApi.deleteData(moduleId);
      if (success) {
        toast.success(t("layout.deleted_successfully"));
        navigate("/permission-module");
      }

    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/settings"}>{t("menu.settings")}</Link>
        <Link to={"/role"}>{t("menu.role")}</Link>
        <Link to={"/permission-module"}>{t("permission.permission_management")}</Link>
        <Typography color="text.primary">{t("layout.edit")}</Typography>
      </Breadcrumbs>
      <BaseInfo
        formik={formik}
        haveDeletePermission={haveDeletePermission}
        deletePermissionModule={deletePermissionModule}
      />
      <ActionFooter onSubmit={update} onCancel={onCancel} />
    </Container>
  );
}
