import { Breadcrumbs, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

//components
import ActionFooter from "../../../components/ActionFooter";
import FullScreenLoading from "../../../components/FullScreenLoading";
import OptionBaseInfo from "../../../components/product/OptionBaseInfo";

// api
import { ProductApi } from "../../../api/ProductApi";

export default function Create() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState({
    name: "",
    values: [],
    taskLocation: "",
  });
  const [fullScreenLoading, setFullScreenLoading] = useState(false);

  const onSubmit = async () => {
    try {
      if (!data.name || !data.values.length) {
        toast.error(t("error.please_enter_all_required_fields"));
        return;
      }
      setFullScreenLoading(true);

      const id = await ProductApi.createProductOption(data);
      if (id) {
        toast.success(t("layout.created_successfully"));
        navigate(`/products/options`);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/settings"}>{t("menu.settings")}</Link>
        <Link to={"/products/options"}>{t("product.product_options")}</Link>
        <Typography color="text.primary">{t("layout.create")}</Typography>
      </Breadcrumbs>
      <OptionBaseInfo data={data} setData={setData} />
      <ActionFooter onSubmit={onSubmit} onCancel={onCancel} />
    </Container>
  );
}
