import { Breadcrumbs, Container, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Translation, useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useSelector } from "react-redux";

// helper
import { checkPermission } from "../../helper/PermissionHelper";
import { pageName } from "../../reducers/commonSlice";
import { user } from "../../reducers/userSlice";

// components
import ActionFooter from "../../components/ActionFooter";
import MemberSkeleton from "../../components/DetailsSkeleton";
import FullScreenLoading from "../../components/FullScreenLoading";
import BaseInfo from "../../components/member/BaseInfo";
import OrderHistory from "../../components/member/OrderHistory";

// api
import { MemberApi } from "../../api/MemberApi";

// validation schema
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required(
      <Translation>{t => t("error.email_is_required")}</Translation>,
    )
    .email(
      <Translation>{t => t("error.invalid_email_format")}</Translation>
    ),
  phoneNumber: Yup.string()
    .min(8, <Translation>{t => t("error.phone_number_min_length")}</Translation>)
    .matches(/^[0-9]*$/, <Translation>{t => t("error.phone_number_is_invalid")}</Translation>),
    memberLevel: Yup.number()
    .min(0, <Translation>{t => t("error.number_must_be_greater_than_zero")}</Translation>)
});

export default function Edit({ }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      remark: "",
      photo: "",
      memberLevel: null,
    },
    validateOnMount: true,
    validationSchema: validationSchema,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  let memberId = id;
  const [haveDeletePermission, setHaveDeletePermission] = useState(false);
  const currentUser = useSelector(user);
  const currentPage = useSelector(pageName);

  useEffect(() => {
    if (!memberId) {
      navigate(-1);
    }
    fetchData();
  }, []);

  useEffect(() => {
    const deletePermission = checkPermission(currentUser, currentPage, "delete");

    setHaveDeletePermission(deletePermission);
  }, [currentUser, currentPage]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await MemberApi.details(memberId);
      formik.setValues(response);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const update = async () => {
    try {
      formik.setTouched({
        email: true,
        phoneNumber: true,
        memberLevel: true,
      });

      formik.validateForm();

      if (!formik.isValid) {
        return;
      }

      const data = {
        ...formik.values,
      };

      setFullScreenLoading(true);

      const success = await MemberApi.update(memberId, data);

      if (success) {
        toast.success(t("layout.updated_successfully"));
        fetchData();
      }

    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  const onDelete = async () => {
    try {
      setFullScreenLoading(true);
      const success = await MemberApi.deleteData(id);
      if (success) {
        toast.success(t("layout.deleted_successfully"));
        navigate("/members");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  if (isLoading) {
    return <MemberSkeleton />;
  }

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/members"}>{t("menu.members")}</Link>
        <Typography color="text.primary">{t("layout.edit")}</Typography>
      </Breadcrumbs>
      <BaseInfo
        formik={formik}
        onDelete={onDelete}
        haveDeletePermission={haveDeletePermission}
      />
      <OrderHistory memberId={memberId} />
      <ActionFooter onSubmit={update} onCancel={onCancel} />
    </Container>
  );
}
