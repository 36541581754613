import { Box, Breadcrumbs, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// components
import ActionFooter from "../../components/ActionFooter";
import FullScreenLoading from "../../components/FullScreenLoading";
import BaseInfo from "../../components/inventory/BaseInfo";

// api
import { InventoryApi } from "../../api/InventoryApi";

export default function Create() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState({
    id: null,
    name: "",
  });
  const [fullScreenLoading, setFullScreenLoading] = useState(false);

  const onSubmit = async () => {
    try {
      if (!data.name) {
        toast.error(t("error.please_enter_all_required_fields"));
        return;
      }
      setFullScreenLoading(true);
      const id = await InventoryApi.create(data);
      if (id) {
        toast.success(t("layout.created_successfully"));
        navigate(`/inventory/${id}`);
      }
  
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/inventory"}>{t("menu.inventory")}</Link>
        <Typography color="text.primary">{t("layout.create")}</Typography>
      </Breadcrumbs>
      <Box component="form">
        <BaseInfo data={data} setData={setData} />
        <Box sx={{ my: 8 }} />
        <ActionFooter onSubmit={onSubmit} onCancel={onCancel} />
      </Box>
    </Container>
  );
}
