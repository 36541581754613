import { Breadcrumbs, Container, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Translation, useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

// components
import ActionFooter from "../../components/ActionFooter";
import MemberSkeleton from "../../components/DetailsSkeleton";
import FullScreenLoading from "../../components/FullScreenLoading";
import BaseInfo from "../../components/user/BaseInfo";

// api
import { RoleApi } from "../../api/RoleApi";
import { UserApi } from "../../api/UserApi";

// validation schema
const validationSchema = Yup.object().shape({
  avatarId: Yup.number()
    .required(<Translation>{t => t("error.please_fill_in_this_field")}</Translation>)
    .test(
      "isPostive?",
      <Translation>{t => t("error.invalid_format")}</Translation>,
      val => val > 0 && val < 7,
    ),
  nameZh: Yup.string().required(
    <Translation>{t => t("error.please_fill_in_this_field")}</Translation>,
  ),
  nameEn: Yup.string().required(
    <Translation>{t => t("error.please_fill_in_this_field")}</Translation>,
  ),
  rank: Yup.string().required(
    <Translation>{t => t("error.please_fill_in_this_field")}</Translation>,
  ),
  // email: Yup.string()
  //   // .required(<Translation>{t => t("error.please_fill_in_this_field")}</Translation>)
  //   .email(<Translation>{t => t("error.invalid_format")}</Translation>),
  username: Yup.string().required(
    <Translation>{t => t("error.please_fill_in_this_field")}</Translation>,
  ),
  phoneNumber: Yup.string()
    // .required(<Translation>{t => t("error.please_fill_in_this_field")}</Translation>)
    .matches(/^[0-9]*$/, {
      excludeEmptyString: false,
      message: <Translation>{t => t("error.invalid_format")}</Translation>,
    })
    .test(
      "minLength",
      <Translation>{t => t("error.phone_min_length")}</Translation>,
      val => val === undefined || val.length < 1 || val.length > 7,
    ),
  // nfc: Yup.string().required(
  //   <Translation>{t => t("error.please_fill_in_this_field")}</Translation>,
  // ),
  password: Yup.string()
    .min(8, <Translation>{t => t("error.password_min_length")}</Translation>)
    .matches(/((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/, {
      excludeEmptyString: false,
      message: <Translation>{t => t("error.password_letter")}</Translation>,
    }),
});

export default function Edit({}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const formik = useFormik({
    initialValues: {
      avatarId: 1,
      username: "",
      nameZh: "",
      nameEn: "",
      rank: "",
      email: "",
      phoneNumber: "",
      roles: [],
      nfc: "",
      remark: "",
      password: "",
    },
    validateOnMount: true,
    validationSchema: validationSchema,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [rolePage, setRolePage] = useState(1);
  const [rolePageSize, setRolePageSize] = useState(-1);
  const [roleFilter, setRoleFilter] = useState([]);

  const fetchRoleData = async () => {
    try {
      const response = await RoleApi.list(rolePage, rolePageSize, roleFilter);
      setOptions(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRoleData();
  }, []);

  useEffect(() => {
    if (!id) {
      navigate(-1);
      return;
    }
    if (options.length == 0) {
      return;
    }
    fetchData();
  }, [options]);

  const fetchData = async () => {
    try {
      const result = await UserApi.details(id);
      result.roles = result.roles.map(role => {
        return {
          label: options.find(option => option.id == role).name,
          value: role,
        };
      });

      formik.setValues(result);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const update = async () => {
    try {
      formik.setTouched({
        avatarId: true,
        username: true,
        nameZh: true,
        nameEn: true,
        rank: true,
        // email: true,
        phoneNumber: true,
        // nfc: true,
        password: true,
      });
      if (!formik.isValid) {
        return;
      }
      let hasError = false;

      if (formik.values.roles.length == 0 || !formik.values.roles) {
        toast.error(t("error.please_select_role"));
        hasError = true;
      }

      if (hasError) {
        return;
      }

      if (formik.values.phoneNumber) {
        formik.values.phoneNumber = parseInt(formik.values.phoneNumber);
      }

      const user = {
        ...formik.values,
      };

      setFullScreenLoading(true);

      const success = await UserApi.update(id, user);
      if (success) {
        toast.success(t("layout.updated_successfully"));
        fetchData();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  const onDelete = async () => {
    try {
      setFullScreenLoading(true);
      const success = await UserApi.deleteData(id);
      if (success) {
        toast.success(t("layout.deleted_successfully"));
        navigate("/users");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  if (isLoading) {
    return <MemberSkeleton />;
  }

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/settings"}>{t("menu.settings")}</Link>
        <Link to={"/users"}>{t("menu.users")}</Link>
        <Typography color="text.primary">{t("layout.edit")}</Typography>
      </Breadcrumbs>
      <BaseInfo formik={formik} onDelete={onDelete} />
      <ActionFooter onSubmit={update} onCancel={onCancel} />
    </Container>
  );
}
