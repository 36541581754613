import { Box, Breadcrumbs, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

// components
import ActionFooter from "../../components/ActionFooter";
import FullScreenLoading from "../../components/FullScreenLoading";
import Skeleton from "../../components/category/Skeleton";
import BaseInfo from "../../components/supplier/BaseInfo";

// api
import { SupplierApi } from "../../api/SupplierApi";

export default function Edit() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({
    name: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [fullScreenLoading, setFullScreenLoading] = useState(false);

  useEffect(() => {
    if (!id) {
      navigate(-1);
    }
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await SupplierApi.details(id);
      setData(response);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const validateForm = () => {
    let hasError = false;

    if (!data.name) {
      hasError = true;
      toast.error(t("Name is required"));
    }

    return hasError;
  };

  const update = async () => {
    try {
      let error = validateForm();

      if (error) {
        return;
      }

      setFullScreenLoading(true);

      const success = await SupplierApi.update(id, data);
      if (success) {
        toast.success(t("layout.updated_successfully"));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onDelete = async () => {
    try {
      setFullScreenLoading(true);
      const success = await SupplierApi.deleteData(id);
      if (success) {
        toast.success(t("layout.deleted_successfully"));
        navigate("/suppliers");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/suppliers"}>{t("menu.suppliers")}</Link>
        <Typography color="text.primary">{t("layout.edit")}</Typography>
      </Breadcrumbs>
      <Box component="form">
        <BaseInfo data={data} setData={setData} onDelete={onDelete} />
        <Box sx={{ my: 8 }} />
        <ActionFooter onSubmit={update} onCancel={onCancel} />
      </Box>
    </Container>
  );
}
