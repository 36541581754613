import { Box, Button, Card, CardActions, CardContent, FormGroup } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { Translation, useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import * as Yup from "yup";

// helper
import { AuthHelper } from "../helper/AuthHelper";

// components
import AppVersion from "../components/AppVersion";
import ForgotPassword from "../components/ForgotPassword";
import ValidationTextField from "../components/ValidationTextField";

// api
import { UserApi } from "../api/UserApi";

const LoginSchema = Yup.object().shape({
  username: Yup.string().required(
    <Translation>{t => t("error.please_fill_in_this_field")}</Translation>,
  ),
  password: Yup.string().required(
    <Translation>{t => t("error.please_fill_in_this_field")}</Translation>,
  ),
});

export default function Login() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  // get search params
  const [searchParams, setSearchParams] = useSearchParams();

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validateOnMount: true,
    validationSchema: LoginSchema,
  });

  const login = async () => {
    try {
      formik.setTouched({
        username: true,
        password: true,
      });

      formik.validateForm();

      if (!formik.isValid) {
        return;
      }

      setIsLoading(true);

      const response = await UserApi.login(formik.values.username, formik.values.password);

      const data = response.data;

      if (data && data.success) {
        const result = data.result;
        const userInfo = {
          jwt: result.jwt,
          userId: result.user.id,
          username: result.user.username,
          email: result.user.email,
          roles: result.user.roles,
          permissions: result.user.permission,
        };
        // redux does not work, because the react app will be reloaded
        // dispatch(updateUserInfo(userInfo));
        AuthHelper.loginProgress({ user: userInfo });
        // if (checkDeviceLock(userInfo)) {
        //   return;
        // }
        // navigate("/");
        // focus update the react app

        if (searchParams.get("redirect")) {
          window.location.href = searchParams.get("redirect");
          return;
        }

        window.location.href = "/";
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      display={"flex"}
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{
        minHeight: "100vh",
        // backgroundImage: "url(/assets/images/environment.webp)",
      }}
    >
      <Card raised sx={{ width: 600, p: 4 }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              pb: 4,
            }}
          >
            <Box component={"img"} src={"/assets/icons/logo_long.png"} height={50} />
          </Box>
          <FormGroup>
            <Box sx={{ my: 2 }}>
              <ValidationTextField
                name={"username"}
                type={"text"}
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title={t("layout.username")}
                errorText={formik.errors.username}
                touched={formik.touched.username}
              />
            </Box>
            <Box sx={{ my: 2 }}>
              <ValidationTextField
                name={"password"}
                type={"password"}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title={t("layout.password")}
                errorText={formik.errors.password}
                touched={formik.touched.password}
                onKeyDown={event => {
                  if (event.key == "Enter") {
                    login();
                  }
                }}
              />
            </Box>
          </FormGroup>
        </CardContent>
        <CardActions>
          <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <Button
              disabled={isLoading}
              fullWidth
              variant="outlined"
              sx={{ p: 2 }}
              onClick={() => {
                login();
              }}
            >
              <Translation>{t => t("layout.login")}</Translation>
            </Button>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box>
                <ForgotPassword />
              </Box>
              <Box>
                <AppVersion />
              </Box>
            </Box>
          </Box>
        </CardActions>
      </Card>
    </Box>
  );
}
