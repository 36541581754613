import {
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  CardHeader,
  Container,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

// helper
import { getVariantName } from "../../helper/UnitsHelper";

// icons
import SearchIcon from "@mui/icons-material/Search";

// components
import ActionFooter from "../../components/ActionFooter";
import DebugLogger from "../../components/DebugLogger";
import FullScreenLoading from "../../components/FullScreenLoading";
import Skeleton from "../../components/category/Skeleton";

// api
import { InventoryApi } from "../../api/InventoryApi";

export default function Management() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [data, setData] = useState([]);
  const [searchStr, setSearchStr] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const inventoryId = parseInt(searchParams.get("inventory_id"));
  const [filter, setFilter] = useState([{ field: "inventories", value: inventoryId }]);

  const stockActions = [
    { label: t("inventory.restock"), value: "restock" },
    { label: t("inventory.discard"), value: "discard" },
  ];

  useEffect(() => {
    fetchProducts();
  }, [filter]);

  const onChangeSearchField = value => {
    setSearchStr(value);
  };

  const search = async () => {
    setFilter([
      { field: "title", value: searchStr },
      { field: "inventories", value: inventoryId },
    ]);
  };

  const fetchProducts = async () => {
    try {
      setFullScreenLoading(true);
      const response = await InventoryApi.stockQuery(inventoryId, 1, -1, filter);
      console.log(response.data);

      const formattedData = response.data.map(product => ({
        ...product,
        variants: product.variants.map(variant => ({
          ...variant,
          action: "restock",
          change: 0,
          remark: "",
        })),
      }));

      setProducts(formattedData);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const cancel = () => {
    navigate(-1);
  };

  const submit = () => {
    const data = {
      inventoryId: inventoryId,
      changes: products
        .map(product =>
          product.variants.map(variant => {
            return {
              variantId: variant.id,
              action: variant.action,
              qtyChange:
                variant.action == "restock"
                  ? parseInt(variant.change || 0)
                  : parseInt(variant.change || 0) * -1,
              remark: variant.remark,
            };
          }),
        )
        .flat()
        .filter(d => d.qtyChange != 0),
    };

    if (data.changes.length <= 0) {
      return;
    }

    const success = InventoryApi.stockBulkChange(data);

    if (success) {
      toast.success(t("layout.updated_successfully"));
      fetchProducts();
      reset();
    }
  };

  const reset = () => {
    setSearchStr("");
    setFilter([{ field: "inventories", value: inventoryId }]);
  };

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/inventory"}>{t("menu.inventory")}</Link>
        <Typography color="text.primary">{t("inventory.inventory_management")}</Typography>
      </Breadcrumbs>
      <Card variant="outlined" sx={{ mb: 5 }}>
        <CardHeader title={t("inventory.inventory_management")} />
        <CardContent>
          <Box xs={12}>
            <DebugLogger title={"Inventory List"} data={data} hidden={true} />
            <DebugLogger title={"Selected Inventory"} data={inventoryId} hidden={true} />
          </Box>

          <Box>
            <Box>
              <TextField
                sx={{ p: 1 }}
                fullWidth
                variant="outlined"
                placeholder={t("layout.search_by_product_name")}
                onChange={event => {
                  onChangeSearchField(event.target.value);
                }}
                onKeyDown={event => {
                  if (event.key == "Enter") {
                    search();
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => {
                        search();
                      }}
                      edge="end"
                    >
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
              />
            </Box>

            <Table sx={{ m: 1, minWidth: 720 }}>
              <TableHead>
                <TableRow>
                  <TableCell width={"10%"}>{t("layout.name")}</TableCell>
                  <TableCell width={"10%"}>{t("layout.variants")}</TableCell>
                  <TableCell width={"10%"}>{t("inventory.current_stock")}</TableCell>
                  <TableCell width={"10%"}>{t("layout.action")}</TableCell>
                  <TableCell width={"10%"}>{t("inventory.qty")}</TableCell>
                  <TableCell width={"10%"}>{t("layout.remark")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products?.map((product, index) =>
                  product.variants.map((variant, variantIndex) => (
                    <TableRow key={variantIndex}>
                      <TableCell>{product.title}</TableCell>
                      <TableCell>{getVariantName(variant) || "-"}</TableCell>
                      <TableCell>{variant.stock || 0}</TableCell>
                      <TableCell>
                        <Select
                          value={variant.action}
                          onChange={(event, newValue) => {
                            console.log(event.target.value);

                            const newProducts = JSON.parse(JSON.stringify(products));

                            newProducts[index].variants[variantIndex].action = event.target.value;

                            setProducts(newProducts);
                          }}
                        >
                          {stockActions.map((action, index) => (
                            <MenuItem key={index} value={action.value}>
                              {action.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                      <TableCell>
                        <TextField
                          type={"number"}
                          value={variant.change}
                          onChange={event => {
                            const newProducts = JSON.parse(JSON.stringify(products));

                            newProducts[index].variants[variantIndex].change = event.target.value;

                            setProducts(newProducts);
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          onChange={event => {
                            const newProducts = JSON.parse(JSON.stringify(products));

                            newProducts[index].variants[variantIndex].remark = event.target.value;

                            setProducts(newProducts);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  )),
                )}
              </TableBody>
            </Table>
          </Box>
        </CardContent>
      </Card>

      <ActionFooter onCancel={cancel} onSubmit={submit} />
    </Container>
  );
}
