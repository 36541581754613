import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Collapse, ListItemButton, ListItemText, Slide, Stack, TextField } from "@mui/material";
import React, { useState } from "react";

import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function General({ data, setData }) {
  const { t } = useTranslation();
  const [openGeneral, setOpenGeneral] = useState(true);

  const handleOpenGeneral = () => {
    setOpenGeneral(!openGeneral);
  };

  return (
    <Box component={"div"}>
      {/* General */}
      <ListItemButton onClick={handleOpenGeneral}>
        <ListItemText primary={t("layout.general")} />
        {openGeneral ? <RemoveIcon /> : <AddIcon />}
      </ListItemButton>
      <Collapse in={openGeneral} timeout="auto" unmountOnExit>
        <Box p={2}>
          <Stack spacing={2}>
            <TextField
              value={data.name}
              onChange={e => {
                setData({ ...data, name: e.target.value });
              }}
              label={t("layout.name")}
              variant="outlined"
              fullWidth
            />
            <TextField
              value={data.reqMemberLevel || 0}
              onChange={(e) => {
                setData({
                  ...data,
                  reqMemberLevel: e.target.value,
                });
              }}
              type={"number"}
              label={t("layout.member_level")}
              variant="outlined"
              fullWidth
            />
          </Stack>
        </Box>
      </Collapse>
    </Box>
  );
}
