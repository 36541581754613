import { Box, Breadcrumbs, Container, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

// helper
import { getServiceProductType } from "../../helper/GlobalValueHelper";
const ingredient =
  '<figure class="table"><table><thead><tr><th colspan="2"><p style="text-align:center;">營養標籤</p></th></tr></thead><tbody><tr><td colspan="2"><p style="text-align:right;">每100克 / Per100g</p></td></tr><tr><td>能量/Energy</td><td><p style="text-align:right;">千克/kcal</p></td></tr><tr><td>蛋白質/Protein</td><td><p style="text-align:right;">克/g</p></td></tr><tr><td>總脂肪/Total fat</td><td><p style="text-align:right;">克/g</p></td></tr><tr><td>&nbsp; &nbsp; - 飽和脂肪/Saturated fat</td><td><p style="text-align:right;">克/g</p></td></tr><tr><td>&nbsp; &nbsp; - 反式脂肪</td><td><p style="text-align:right;">克/g</p></td></tr><tr><td>碳水化合物/Carbohydrates</td><td><p style="text-align:right;">克/g</p></td></tr><tr><td>&nbsp; &nbsp; - 糖/Sugars</td><td><p style="text-align:right;">克/g</p></td></tr><tr><td>鈉/Sodium</td><td><p style="text-align:right;">毫克/mg</p></td></tr></tbody></table></figure>';

// components
import ActionFooter from "../../components/ActionFooter";
import FullScreenLoading from "../../components/FullScreenLoading";
import Images from "../../components/product/Images";
import BaseInfo from "../../components/product/BaseInfo";
import Service from "../../components/product/Service";
import Simple from "../../components/product/Simple";
import Variants from "../../components/product/Variants";

// api
import { ProductApi } from "../../api/ProductApi";

export default function Create() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState({
    title: "",
    type: "normal",
    basePrice: 0,
    inventories: [],
    categories: [],
    supplier: null,
    description: "",
    options: [],
    variants: [],
    images: [],
    isSimple: true,
    trackInventory: true,
    printKitchenReceipt: false,
    isAvailable: true,
    services: [],
  });
  const [fullScreenLoading, setFullScreenLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const isApp = searchParams.get("isApp");

  const onSubmit = async () => {
    try {
      // console.log("submit", product);

      let newProduct = { ...data };

      if (!newProduct.title) {
        toast.error("please input product name");
        return;
      }

      if (newProduct.categories.length <= 0) {
        toast.error("please select category");
        return;
      }

      if (newProduct.options.length <= 0) {
        toast.error("please select options");
        return;
      }

      if (newProduct.cost < 0 || newProduct.cost == "" || !newProduct.cost) {
        newProduct.cost = 0;
      }

      if (newProduct.price < 0 || newProduct.price == "" || !newProduct.price) {
        newProduct.price = 0;
      }

      setFullScreenLoading(true);
      const id = await ProductApi.createProduct(newProduct);
      if (id) {
        toast.success(t("layout.created_successfully"));

        if (isApp) {
          navigate(`/products/${id}?isApp=${isApp}`);
        } else {
          navigate(`/products/${id}`);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/products"}>{t("menu.products")}</Link>
        <Typography color="text.primary">{t("layout.create")}</Typography>
      </Breadcrumbs>
      <Box component="form">
        <BaseInfo data={data} setData={setData} />
        <Box sx={{ my: 4 }} />
        {data.isSimple ? (
          <Simple data={data} setData={setData} />
        ) : (
          <Variants data={data} setData={setData} />
        )}
        {data.type == getServiceProductType() && (
          <Box>
            <Box sx={{ my: 4 }} />
            <Service data={data} setData={setData} />
          </Box>
        )}
        <Box sx={{ my: 4 }} />
        <Images data={data} setData={setData} />
        <Box sx={{ my: 8 }} />
        <ActionFooter onSubmit={onSubmit} onCancel={onCancel} />
      </Box>
    </Container>
  );
}
