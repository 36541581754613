import { Box, Breadcrumbs, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

// components
import ActionFooter from "../../../../components/ActionFooter";
import FullScreenLoading from "../../../../components/FullScreenLoading";
import OptionValueBaseInfo from "../../../../components/product/OptionValueBaseInfo";

// api
import { ProductApi } from "../../../../api/ProductApi";

export default function Edit() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const [data, setData] = useState({
    name: "",
  });
  const [isDisabled, setIsDisabled] = useState(false);
  const [fullScreenLoading, setFullScreenLoading] = useState(false);

  useEffect(() => {
    if (!id) {
      navigate(-1);
    }
    if (id == 1) {
      setIsDisabled(true);
    }
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const result = await ProductApi.productOptionValueDetails(id);
      setData(result);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async () => {
    if (!data.name) {
      toast.error(t("error.please_enter_all_required_fields"));
      return;
    }

    const success = await ProductApi.updateProductOptionValue(id, data);

    if (success) {
      toast.success(t("layout.updated_successfully"));
    }
  };

  const onCancel = () => {
    navigate("/products/options/values");
  };

  const deleteData = async () => {
    try {
      setFullScreenLoading(true);
      const success = await ProductApi.deleteProductOptionValue(id);
      if (success) {
        toast.success(t("layout.deleted_successfully"));
        navigate("/products/options/values");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />

      <Box component="form">
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
          <Link to={"/settings"}>{t("menu.settings")}</Link>
          <Link to={"/products/options/values"}>{t("product.option_values")}</Link>
          <Typography color="text.primary">{t("layout.edit")}</Typography>
        </Breadcrumbs>
        <OptionValueBaseInfo data={data} setData={setData} isDisabled={isDisabled} deleteData={deleteData}/>
        <ActionFooter onSubmit={onSubmit} onCancel={onCancel} />
      </Box>
    </Container>
  );
}
