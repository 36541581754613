import { Box, Breadcrumbs, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// components
import ActionFooter from "../../components/ActionFooter";
import FullScreenLoading from "../../components/FullScreenLoading";
import BaseInfo from "../../components/payment/BaseInfo";

// api
import { PaymentApi } from "../../api/PaymentApi";

export default function Create() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState({
    name: "",
    icon: "",
  });
  const [fullScreenLoading, setFullScreenLoading] = useState(false);

  const onSubmit = async () => {
    try {
      setFullScreenLoading(true);

      if (!data.name) {
        toast.error(t("error.please_enter_all_required_fields"));
        setFullScreenLoading(false);
        return;
      }

      const id = await PaymentApi.create(data);
      if (id) {
        toast.success(t("layout.created_successfully"));
        navigate(`/payment-method`);
      }

    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/payment-method"}>{t("layout.payment_method")}</Link>
        <Typography color="text.primary">{t("layout.create")}</Typography>
      </Breadcrumbs>
      <Box component="form">
        <BaseInfo data={data} setData={setData} />
      </Box>
      <ActionFooter onCancel={onCancel} onSubmit={onSubmit} />
    </Container>
  );
}
