import { Box, Breadcrumbs, Container, Typography, Grid2 as Grid, Stack} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

// helper
import { checkPermission } from "../../helper/PermissionHelper";
import { pageName } from "../../reducers/commonSlice";
import { user } from "../../reducers/userSlice";

// components
import ActionFooter from "../../components/ActionFooter";
import FullScreenLoading from "../../components/FullScreenLoading";
import BaseInfo from "../../components/order/BaseInfo";
import Payment from "../../components/order/Payment";
import Skeleton from "../../components/order/Skeleton";
import Summary from "../../components/order/Summary";
import Timeline from "../../components/order/Timeline";

// api
import { OrderApi } from "../../api/OrderApi";

export default function Detail() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({
    id: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  const [haveRefundPermission, setHaveRefundPermission] = useState(false);
  const [haveCancelPermission, setHaveCancelPermission] = useState(false);
  const [haveEditPermission, setHaveEditPermission] = useState(false);
  const currentUser = useSelector(user);
  const currentPage = useSelector(pageName);

  useEffect(() => {
    if (!id) {
      navigate(-1);
    }
    fetchData();
  }, []);

  useEffect(() => {
    const refundPermission = checkPermission(currentUser, currentPage, "refund");
    const cancelPermission = checkPermission(currentUser, currentPage, "cancel");
    const editPermission = checkPermission(currentUser, currentPage, "payment");

    setHaveRefundPermission(refundPermission);
    setHaveCancelPermission(cancelPermission);
    setHaveEditPermission(editPermission);
  }, [currentUser, currentPage]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await OrderApi.details(id);
      setData(response);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  const cancelOrder = async () => {
    try {
      setFullScreenLoading(true);
      const success = await OrderApi.cancel(id);

      if (success) {
        toast.success(t("order.cancel_successfully"));
      }

      fetchData();
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const refundOrder = async () => {
    try {
      setFullScreenLoading(true);
      const success = await OrderApi.refund(id);

      if (success) {
        toast.success(t("order.refund_successfully"));
      }

      fetchData();
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  if (isLoading) {
    return <Skeleton />;
  }

  const validateForm = () => {
    let hasError = false;

    data.items.forEach((item, index) => {
      if (item.shipQty < 0) {
        hasError = true;
        toast.error(`Item ${index + 1}: ${t("error.number_must_be_greater_than_zero")}`);
      }

      if (item.shipQty > item.qty) {
        hasError = true;
        toast.error(`Item ${index + 1}: ${t("error.ship_qty_can_not_greate_than_order_qty")}`);
      }
    });

    return hasError;
  };

  const update = async () => {
    let error = validateForm();

    if (error) {
      return;
    }

    let order = [...data.items];
    try {
      setFullScreenLoading(true);

      const success = await OrderApi.batchUpdateItemShipQty(order);
      if (success) {
        toast.success(t("layout.updated_successfully"));
        fetchData();
      }

    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/orders"}>{t("menu.orders")}</Link>
        <Typography color="text.primary">{data.orderNum}</Typography>
      </Breadcrumbs>
      <Box pb={8}>
        <Grid container spacing={2}>
          <Grid size={{
            xs: 12,
            lg: 8,
          }}>
            <Stack spacing={2}>
            <BaseInfo
              data={data}
              cancelOrder={cancelOrder}
              refundOrder={refundOrder}
              haveRefundPermission={haveRefundPermission}
              haveCancelPermission={haveCancelPermission}
            />

            <Summary data={data} setData={setData} />

            <Payment
              data={data}
              fetchData={fetchData}
              haveEditPermission={haveEditPermission}
            />
            </Stack>
          </Grid>

          <Grid size={{
            xs: 12,
            lg: 4,
          }}>
            <Timeline data={data} />
          </Grid>
        </Grid>
      </Box>
      <ActionFooter onCancel={onCancel} onSubmit={update} />
    </Container>
  );
}
